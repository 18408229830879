<template>
  <div :class="{ card: true, mobile: isMobile }">
    <div class="d-flex align-center contain flex-column flex-lg-row">
      <div class="d-flex align-lg-center align-top flex-row">
        <img src="../../../public/img/icons/d_01.png" class="iphone" />
        <div class="flex-column ml-3">
          <p class="text-color-40">Empower Your Digital Assets</p>

          <p class="text-color-17">
            Create, collect and trade NFTs on the go with the Uptick NFT
            Marketplace.
          </p>
          <p class="text-color-17">Available on web and mobile.</p>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex align-center flex-row ml-lg-10 ml-0 right">
          <div class="flex-lg-column" v-if="!isMobile">
            <img src="@/assets/image/qr-code.jpeg" width="150" />
            <p class="text-color-14">Scan to Download</p>
          </div>
          <div class="flex-column ml-lg-10 ml-0 pl-lg-0 pl-6">
            <img
              src="../../../public/img/icons/d_app store.png"
              width="250"
              style="cursor: pointer"
              @click="btn_appstore"
            />
            <img
              src="../../../public/img/icons/d_testflight.png"
              width="250"
              style="cursor: pointer"
              @click="btn_testflight"
            />
            <p class="text-color-12">
              Due to Apple policy, the Lite version is available in the
              Appstore, and you can download the full-featured version from
              Testflight.
            </p>
            <img
              src="../../../public/img/icons/d_google play.png"
              width="250"
              style="cursor: pointer"
              @click="btn_google"
            />
            <img
              src="../../../public/img/icons/d_android.png"
              width="250"
              style="cursor: pointer"
              @click="btn_android"
            />
          </div>
        </div>
        <div class="text-color-18 ml-lg-10 ml-0 mt-6">
          * The current version of the app is only for testing purposes due to
          policies enforced by the major application markets. As a result, there
          may be some changes made to the app in the future. Please ensure you
          have safely stored your mnemonic and private keys.
        </div>
      </div>
    </div>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    // btn_appstore(){
    // 	this.$toast("success","In review, stay stunned.");
    // },
    btn_appstore() {
      window.open(
        'https://apps.apple.com/us/app/uptick-nft-marketplace/id6446351918',
        '_blank'
      );
    },

    btn_google() {
      window.open(
        'https://play.google.com/store/apps/details?id=com.uptick.marketplace.irisnet',
        '_blank'
      );
    },
    btn_testflight() {
      window.open('https://testflight.apple.com/join/B5no7LfQ', '_blank');
    },
    btn_android() {
      window.open(
        'https://file.uptick.network/app/uptickIrisnet.apk',
        '_blank'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  .contain {
    position: relative;
    padding: 70px 80px 80px 40px;
    .iphone {
      width: 267px;
      height: 334px;
    }

    .text-color-40 {
      width: 280px;
      font-family: Helvetica-Bold;
      font-size: 40px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .text-color-17 {
      width: 340px;
      font-family: Helvetica;
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: #270645;
    }
    .text-color-14 {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 1px;
      color: #270645;
      text-align: center;
    }
    .text-color-18 {
      font-family: Helvetica;
      font-size: 14px;
      font-weight: normal;
      font-style: italic;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #812acb;
      word-break: break-word;
    }
    .text-color-12 {
      text-align: start;
      width: 255px;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
      word-break: break-word;
    }

    .img-normal {
      width: 100%;
      height: auto;
    }
    .right {
      background-color: #ffffff;
      border-radius: 5px;
      border: solid 1px #cccccc;
      padding: 20px;
    }
  }
  &.mobile {
    width: 100%;
    .contain {
      padding: 20px 20px 100px;
    }
    .iphone {
      width: 142px;
      height: 177px;
      margin-top: 10%;
    }
    .text-color-40 {
      width: 167px;
      font-family: Helvetica-Bold;
      font-size: 30px;
      line-height: 38px;
    }
    .text-color-17 {
      width: 166px;
      font-family: Helvetica;
      font-size: 14px;
      letter-spacing: 0px;
    }
    .text-color-18 {
      width: 330px;
    }
    .right {
      width: 331px;
      height: 387px;
      background-color: #ffffff;
      border-radius: 5px;
      border: solid 1px #cccccc;
    }
  }
}
</style>
